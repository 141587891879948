import request from "@/api/http";

/**
 * 获取主链列表
 */
export const getChaninCode = () => {
  return request({
    url: "/api/system/chain/codes",
    method: "get",
  });
};

/**
 * 获取币种列表
 *
 * @params {chainCode} 主链Code
 */
export const getCodeByChain = (chainCode) => {
  return request({
    url: "/api/system/chaintoken/chaintokens",
    method: "get",
    params: {
      chainCode,
    },
  });
};

/**
 * 添加监控
 * 
  "addr": "0x5e6fe60401280fa5975426fb98481a92c4517579",
  "chain": "ETH",  //通过链的接口获取编码
  "token": "ETH", //币种
  "emailPush": true,  //开启邮箱
  "prjId": 6,   // 项目id
  "remark": "测试",
  "telegramPush": false,  //开启telegram
  "validity": 30, // 有效时间
  "wechatPush": true  //开启微信
 */
export const saveMonitor = (data) => {
  return request({
    url: `/api/biz${data.chain.toLowerCase()}/monitor/add`,
    method: "post",
    data,
  });
};

export const updateMonitor = (data) => {
  return request({
    url: `/api/biz${data.chain.toLowerCase()}/monitor/update`,
    method: "post",
    data,
  });
};
export const delMonitor = (data) => {
  return request({
    url: `/api/biz${data.chain.toLowerCase()}/monitor/delete`,
    method: "post",
    params:{
      id:data.id,
    },
  });
};

/**
 * 获取监控列表
 */
export const getMonitorList = (pageNo, pageSize, prjId,addr,tokenCode) => {
  return request({
    url: `/api/system/monitor/pagelist`,
    method: "post",
    data: {
      pageNo,
      pageSize,
      prjId,
      addr
    },
  });
};

/**
 * 获取报告列表
 * 
 * @params {id} 监控id
 */
 export const getReportList = (pageNo, pageSize, id,symbol,token) => {
  return request({
    url: `/api/biz${token.toLowerCase()}/monitor/report/pagelist`,
    method: "post",
    data: {
      pageNo,
      pageSize,
      id,
      symbol
    },
  });
};

/**
 * 获取监控图标数据
 * 
 * @params {id} 监控id
 */
 export const getReportChart = (id,symbol) => {
  return request({
    url: "/api/system/monitor/report/chart",
    method: "get",
    params: {
      id,
      symbol
    },
  });
};

/**
 * 获取监控支持的币种列表
 * 
 * @params {id} 监控id
 */
 export const getReportChartTokens = (id) => {
  return request({
    url: "/api/system/monitor/report/chart/tokens",
    method: "get",
    params: {
      id,
    },
  });
};

