<template>
  <div class="monitor_report">
    <el-form ref="form" :model="form" label-width="50px">
      <el-form-item label="币种：">
        <el-select v-model="form.symbol" placeholder="请选择" size="mini" @change="symbolChanged">
          <el-option v-for="item in tokenList" :key="item" :label="item" :value="item"> </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="monitor_report_top" ref="mychart"></div>
    <div class="monitor_report_bottom">
      <div class="table">
        <el-table :data="reportList" height="100%" header-cell-class-name="proj_table_header">
          <el-table-column
            prop="time"
            label="交易时间"
            header-align="center"
            align="center"
            width="200"
          >
          </el-table-column>
          <el-table-column
            prop="addr"
            label="交易类型"
            header-align="center"
            align="center"
            width="80"
          >
            <template slot-scope="scope">{{ scope.row.type == "0" ? "流入" : "流出" }} </template>
          </el-table-column>
          <el-table-column
            prop="amount"
            label="交易数量"
            header-align="center"
            align="center"
            width="200"
          >
          </el-table-column>
          <el-table-column prop="destAddr" label="对手地址" header-align="center" align="center">
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <el-pagination
          class="proj_table_pagination"
          :background="true"
          layout="prev, pager, next,total,jumper"
          :total="total"
          :page-size="pageSize"
          :current-page="pageNo"
          @current-change="currentChange"
          prev-text="上一页"
          next-text="下一页"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getReportList, getReportChart, getReportChartTokens } from "@/api/monitor";
import * as echarts from "echarts";

let _inList = [],
  _outList = [],
  _amountList = [],
  _dateList = [];
export default {
  name: "monitor_add",
  props: {
    monitor: Object,
  },
  data() {
    return {
      pageNo: 1,
      pageSize: 3,
      total: 0,
      reportList: [],
      form: {
        symbol: null,
      },
      tokenList: [],
    };
  },
  created() {
    this.getReportChartTokens();
  },
  mounted() {},
  methods: {
    getReportList() {
      getReportList(this.pageNo, this.pageSize, this.monitor.id,this.form.symbol,this.monitor.chain).then((res) => {
        this.reportList = res.data.data;
        this.total = res.data.total;
      });
    },
    getReportChart() {
      _inList = [];
      _outList = [];
      _amountList = [];
      _dateList = [];
      getReportChart(this.monitor.id,this.form.symbol).then((res) => {
        res.data.data.forEach((element) => {
          _inList.push(element.in);
          _outList.push(element.out);
          _amountList.push(element.balance);
          _dateList.push(element.time);
        });
        this.initChart();
      });
    },
    getReportChartTokens() {
      getReportChartTokens(this.monitor.id).then((res) => {
        this.tokenList = res.data.data;

        let symbol = this.tokenList.find(item=>item=="ETH")
        this.form.symbol = symbol?symbol:this.tokenList[0]
        
        //调用接口获取报告
        this.getReportList();
        this.getReportChart();
      });
    },
    symbolChanged(val) {
      this.getReportList();
      this.getReportChart();
    },
    currentChange(val) {
      this.pageNo = val;
      this.getReportList();
    },
    initChart() {
      var myChart = echarts.init(this.$refs.mychart);
      myChart.setOption({
        title: {
          text: "资产异动情况",
          left: "center",
        },
        tooltip: {
          trigger: "axis",
          position: function (pt) {
            return [pt[0], "10%"];
          },
        },
        legend: {
          show: true,
          bottom: 0,
          itemGap: 30,
          icon: "circle",
        },
        xAxis: {
          type: "category",
          data: _dateList,
        },
        yAxis: {},
        series: [
          {
            name: "流入",
            data: _inList,
            type: "line",
            smooth: true,
            itemStyle: {
              color: "#00DDC5",
            },
          },
          {
            name: "流出",
            data: _outList,
            type: "line",
            smooth: true,
            itemStyle: {
              color: "#5A8EF8",
            },
          },
          {
            name: "余额",
            data: _amountList,
            type: "line",
            smooth: true,
            itemStyle: {
              color: "#F77448",
            },
          },
        ],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.monitor_report {
  height: 1160px;
  display: flex;
  flex-direction: column;

  .monitor_report_top {
    height: 425px;
  }
  .monitor_report_bottom {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    .table {
      flex: 1;
    }
    .pagination {
      margin: 0 28px;
      background-color: #fff;
      padding: 20px 0;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
