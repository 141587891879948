<template>
  <div class="monitor-list">
    <div class="container">
      <div class="container_top">
        <div class="container_top_left">
          <div class="name">地址</div>
          <div class="filter">
            <div class="filter_input">
              <el-input v-model="addr" placeholder="数字货币地址" clearable=""></el-input>
            </div>
            <div class="filter_btn" @click="searchHandler">筛选</div>
          </div>
        </div>
        <div class="container_top_right">
          <div class="add_btn" @click="addHandler">
            <img src="@/assets/images/monitor/icon-plus.png" />添加监控
          </div>
        </div>
      </div>
      <div class="total">
        共<span>{{ total }}</span
        >条监控记录
      </div>
      <div class="table mt14">
        <el-table :data="monitorList" height="100%" header-cell-class-name="proj_table_header">
          <el-table-column prop="addr" label="地址" header-align="center" align="center">
          </el-table-column>
          <el-table-column prop="amount" label="异动数量" header-align="center" align="center">
          </el-table-column>
          <el-table-column prop="times" label="异动次数" header-align="center" align="center">
          </el-table-column>
          <el-table-column prop="validity" label="有效期" header-align="center" align="center" width="220">
            <template slot-scope="scope">
              <span>{{ scope.row.createTime.substring(0, 10) }}</span> 至
              <span>{{ scope.row.endDate.substring(0, 10) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="提醒方式" header-align="center" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.wechatPush">微信</span
              ><span v-if="scope.row.wechatPush && (scope.row.emailPush || scope.row.telegramPush)"
                >/</span
              ><span v-if="scope.row.emailPush">邮箱</span
              ><span v-if="scope.row.emailPush && scope.row.telegramPush">/</span
              ><span v-if="scope.row.telegramPush">telegram</span>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            header-align="center"
            align="center"
            width="220"
          >
            <template slot-scope="scope">
              <el-button @click="showReport(scope.row)" type="text">查看监控报告</el-button>
              <el-button @click="editRow(scope.row)" type="text">编辑</el-button>
              <el-button @click="delRow(scope.row)" type="text">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <el-pagination
          class="proj_table_pagination"
          :background="true"
          layout="prev, pager, next,total,jumper"
          :total="total"
          :page-size="pageSize"
          :current-page="pageNo"
          @current-change="currentChange"
          prev-text="上一页"
          next-text="下一页"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 项目编辑弹窗 -->
    <el-dialog
      :title="isAdd ? '新增监控' : '编辑监控'"
      :visible.sync="monitorDia"
      :destroy-on-close="true"
      width="620px"
      custom-class="proj_dialog"
      :close-on-click-modal="false"
    >
      <div>
        <monitor-add ref="monitorAdd" :monitorData="monitorData" v-if="monitorDia" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="monitorDia = false">取 消</el-button>
        <el-button type="primary" @click="monitorHandler()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 报告弹窗 -->

    <el-dialog
      title="报告"
      :visible.sync="reportDia"
      :destroy-on-close="true"
      width="860px"
      custom-class="proj_dialog"
      top="50px"
    >
      <div>
        <monitor-report :monitor="monitorData" v-if="reportDia" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getProjectList, addProject, updetaProject, getProjectCode, delPrj } from "@/api/relation";
import MonitorAdd from "./add";
import MonitorReport from "./report";
import { saveMonitor,updateMonitor, getMonitorList,delMonitor } from "@/api/monitor";
import { dateFormat } from "@/utils/date.js";
export default {
  name: "relation",
  props: {
    proj: Object,
    tokenCode:String
  },
  components: {
    MonitorAdd,
    MonitorReport,
  },
  data() {
    return {
      addr: null,
      monitorDia: false,
      reportDia: false,
      isAdd: true,
      monitorList: [],
      pageNo: 1,
      pageSize: 15,
      total: 0,
      curMonitor: null,
      monitorData: null,
    };
  },
  watch: {
    proj: function () {
      this.getMonitorList();
    },
  },
  created() {
    this.getMonitorList();
  },
  methods: {
    getMonitorList() {
      if (!this.proj) return;
      getMonitorList(this.pageNo, this.pageSize, this.proj.id,this.addr,this.tokenCode).then((res) => {
        this.monitorList = res.data.data.map((ele) => {
          let endDateTime = new Date(ele.createTime).getTime() + ele.validity * 24 * 60 * 60 * 1000;
          let endDate = dateFormat(new Date(endDateTime));
          return {
            ...ele,
            endDate,
          };
        });
        this.total = res.data.total;
      });
    },
    searchHandler(){
      this.getMonitorList()
    },
    currentChange(val) {
      this.pageNo = val;
      this.getMonitorList();
    },
    gotoChartList(item) {
      this.$router.push({ name: "chartList", query: { projId: item.id } });
    },
    delRow(item) {
      this.$confirm("确定删除监控?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delMonitor(item).then((res) => {
          if (res.data.code == "0") {
            this.$message.success("删除成功");
            this.getMonitorList();
          }
        });
      });
    },
    addHandler() {
      if (!this.proj) {
        this.$message.error("请选择项目");
        return;
      }

      this.monitorData = null;
      this.isAdd = true;
      this.monitorDia = true;
    },
    editRow(item) {
      this.monitorData = item;
      this.isAdd = false;
      this.monitorDia = true;
    },
    submit() {
      if (this.isAdd) {
        addProject(this.form).then((res) => {
          if (res.data.code == "0") {
            this.$message.success("添加成功");
          }
          this.pageNo = 1;
          this.getMonitorList();
          this.monitorDia = false;
        });
      } else {
        updetaProject(this.form).then((res) => {
          if (res.data.code == "0") {
            this.$message.success("修改成功");
          }
          this.pageNo = 1;
          this.getMonitorList();
          this.monitorDia = false;
        });
      }
    },
    showReport(item) {
      this.monitorData = item;
      this.reportDia = true;
    },
    monitorHandler() {
      if (!this.proj) {
        this.$message.error("请选择项目");
        return;
      }

      if (!this.$refs.monitorAdd.form.addr) {
        this.$message.error("请输入地址");
        return;
      }

      if (this.isAdd) {
        let data = {
          prjId: this.proj.id,
          ...this.$refs.monitorAdd.form,
        };
        saveMonitor(data).then((res) => {
          if (res.data.code == "0") {
            this.$message.success("添加成功");
            this.getMonitorList();
            this.monitorDia = false;
          }
        });
      } else {
        let data = {
          prjId: this.proj.id,
          ...this.$refs.monitorAdd.form,
        };
        updateMonitor(data).then((res) => {
          if (res.data.code == "0") {
            this.$message.success("修改成功");
            this.getMonitorList();
            this.monitorDia = false;
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.monitor-list {
  background-color: #fff;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  .container {
    flex: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin: 0 24px 0 24px;
    border-radius: 4px;
    border: 1px solid #f4eaea;
    padding: 0 28px;
    margin-bottom: 24px;
    .container_top {
      display: flex;
      height: 80px;
      padding: 32px 0;
      .container_top_left {
        margin-top: 3px;
        width: 660px;
        .name {
          height: 20px;
          font-size: 16px;
          font-weight: 600;
          color: #333333;
          line-height: 20px;
        }
        .filter {
          margin-top: 7px;
          display: flex;
          align-items: center;
          .filter_input {
            flex: 1;
            height: 50px;
            background: #ffffff;
            border-radius: 4px 0 4px 4px;
            border: 1px solid #eaeaea;
            border-right: none;
            display: flex;
            align-items: center;
            /deep/.el-input__inner {
              border: none;
            }
            box-sizing: border-box;
          }
          .filter_btn {
            width: 94px;
            height: 50px;
            line-height: 50px;
            color: #fff;
            font-size: 16px;
            background: #2e43eb;
            border-radius: 0px 4px 4px 0px;
            text-align: center;
            box-sizing: border-box;
            cursor: pointer;
          }
        }
      }
      .container_top_right {
        flex: 1;
        justify-content: end;
        display: flex;
        .add_btn {
          width: 120px;
          height: 34px;
          line-height: 34px;
          border-radius: 4px;
          border: 1px solid #2a66ed;
          color: #2e43eb;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          cursor: pointer;
          img {
            height: 21px;
            width: 20px;
            margin-right: 5px;
          }
        }
      }
    }
    .total {
      font-weight: 600;
      span {
        color: #2e43eb;
      }
    }
    .table {
      flex: 1;
      background-color: #fff;
      box-shadow: 0px 4px 8px 0px rgba(240, 240, 240, 0.5);
      border-radius: 4px;
    }
    .pagination {
      // height: 157px;
      background-color: #fff;
      padding: 30px 0;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
