<template>
  <el-form label-position="top" label-width="80px" :model="form" class="monitor_add">
    <el-form-item label="主链" class="monitor_add_select">
      <el-select v-model="form.chain" placeholder="请选择" @change="chainSelected" :disabled="monitorData">
        <div slot="prefix">
          <img
            width="20"
            height="20"
            style="margin-top: 10px; margin-left: 10px"
            :src="form.chainImg"
          />
        </div>
        <el-option v-for="item in chainList" :key="item" :label="item" :value="item">
          <div class="bit-select-item">
            <img width="20" height="20" :src="item.img" />
            <div class="bit-select-item-name">{{ item.code }}</div>
          </div>
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="地址">
      <el-input v-model="form.addr" placeholder="请输入地址" :disabled="monitorData"></el-input>
    </el-form-item>
    <el-form-item label="备注">
      <el-input v-model="form.remark" placeholder="最多可添加20个字符" :maxlength="20"></el-input>
    </el-form-item>
    <el-form-item label="有效期">
      <el-select v-model="form.validity" placeholder="请选择">
        <el-option
          v-for="item in validateOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="通知方式">
        <el-checkbox label="0" v-model="form.wechatPush">微信</el-checkbox>
        <el-checkbox label="1" v-model="form.emailPush">邮箱</el-checkbox>
        <el-checkbox label="2" v-model="form.telegramPush">telegram</el-checkbox>
    </el-form-item>
    <el-form-item>
      <el-input v-if="form.emailPush" v-model="form.email" placeholder="请输入邮箱"></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
import { getChaninCode } from "@/api/monitor";
export default {
  name: "monitor_add",
  props:{
    monitorData:Object
  },
  data() {
    return {
      form: {
        token: "",
        tokenImg: "",
        chain: null,
        chainImg: "",
        validity: 30,
        wechatPush:true,
        emailPush:false,
        telegramPush:false,
      },
      tokenList: [],
      chainList: [],
      validateOptions: [
        {
          value: 30,
          label: "30天",
        },
        {
          value: 60,
          label: "60天",
        },
        {
          value: 90,
          label: "90天",
        },
      ],
    };
  },
  created() {
    if(this.monitorData){
      this.form = {
        ...this.monitorData
      }
    }
    this.getChaninCode();
  },
  methods: {
    getChaninCode() {
      getChaninCode().then((res) => {
        this.chainList = res.data.data;

        if(this.form.chain){
          let item = this.chainList.find(ele=>ele.code == this.form.chain)
          this.form.chainImg = item.img;

        }else{

          this.form.chainImg = this.chainList[0].img;
          this.form.chain = this.chainList[0].code;
        }

      });
    },
    chainSelected(value) {
      let item = this.chainList.find((item) => item.code == value.code);
      
      this.form.chainImg = item.img;
      this.form.chain = item.code;
    },
    tokenSelected(value) {
      let item = this.tokenList.find((item) => item.code == value);
      this.form.tokenImg = item.img;
      this.form.token = item.token;
    },
  },
};
</script>
<style lang="scss" scoped>
.monitor_add {
  .monitor_add_select {
    /deep/.el-select {
      width: 100%;
      .el-input__inner {
        padding-left: 40px;
      }
    }
  }
  /deep/.el-select {
    width: 100%;
  }
}
</style>
